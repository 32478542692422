<template>
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-auto ms-auto">
        <span
          class="badge rounded-pill bg-success cursor-pointer me-2"
          @click="statusFilter = ''"
          >All</span
        >
        <span
          class="badge rounded-pill bg-success cursor-pointer me-2"
          @click="statusFilter = 'dealt_with'"
          >Dealt With</span
        >
        <span
          class="badge rounded-pill bg-success cursor-pointer"
          @click="statusFilter = 'not_dealt_with'"
          >Not Dealt With</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <loading v-if="!emails.data"></loading>
            <ul class="list-group list-group-flush " v-else>
              <li
                class="list-group-item cursor-pointer"
                v-for="(e, eKey) in emails.data"
                :key="e.id"
                :class="eKey % 2 == 0 ? 'bg-light' : ''"
                @click="$router.push('/emails/change/' + e.id)"
              >
                <div class="row">
                  <div class="col-md-3 my-auto">
                    <p class="mb-0">
                      <small>
                        {{ e.sender }}
                      </small>
                    </p>
                  </div>
                  <div class="col my-auto">
                    <p class="mb-0">
                      <small
                        v-html="
                          subjectBodyCombined(
                            e.subject,
                            e.body_stripped,
                            e.status == 'dealt_with' ? true : false
                          )
                        "
                      ></small>
                    </p>
                  </div>
                  <div class="col-auto my-auto ms-auto">
                    <p class="mb-0">
                      <small>{{ e.created_at | formatDate }}</small>
                    </p>
                  </div>
                </div>
              </li>
            </ul>

            <div class="row mt-4">
              <div class="col-md-4 my-auto">
                <button
                  class="btn btn-sm btn-outline-success"
                  @click="fetchChangeEmails(emails.first_page_url)"
                  :disabled="!emails.first_page_url"
                >
                  First Page
                </button>

                <button
                  class="btn btn-sm btn-outline-success ms-3"
                  @click="fetchChangeEmails(emails.prev_page_url)"
                  :disabled="!emails.prev_page_url"
                >
                  Previous
                </button>
              </div>
              <div class="col-md-4 my-auto text-center">
                <p class="mb-0">
                  Page {{ emails.current_page }} of
                  {{ emails.last_page }}
                </p>
              </div>
              <div class="col-md-4 my-auto text-end">
                <button
                  class="btn btn-sm btn-outline-success cursor-pointer me-3"
                  @click="fetchChangeEmails(emails.next_page_url)"
                  :disabled="!emails.next_page_url"
                >
                  Next
                </button>

                <button
                  class="btn btn-sm btn-outline-success cursor-pointer"
                  @click="fetchChangeEmails(emails.last_page_url)"
                  :disabled="!emails.last_page_url"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      emails: [],
      statusFilter: "",
    };
  },
  watch: {
    emails: {
      handler: function(val, old) {
        this.$router.replace({
          path: this.$route.currentPath,
          query: {
            page: val.current_page,
          },
        });
      },
      deep: true,
    },
    statusFilter: {
      handler: function(val, old) {
        if (val != old) {
          this.fetchChangeEmails();
        }
      },
    },
  },
  methods: {
    subjectBodyCombined(subject, body, read) {
      if (subject) {
        if (subject.length > 40) {
          if (read) {
            var newSubject = subject.substring(0, 40) + "...";
          } else {
            var newSubject =
              "<strong>" + subject.substring(0, 40) + "...</strong>";
          }
        } else {
          var newSubject = subject;
        }
      } else {
        var newSubject = "";
      }

      if (body && body.length > 70) {
        var newBody = body.substring(0, 70) + "...";
      } else {
        var newBody = body;
      }

      return newSubject + " - " + newBody;
    },
    fetchChangeEmails(paginationUrl) {
      if (!paginationUrl && this.$route.query.page) {
        var url =
          process.env.VUE_APP_API_URL +
          "/emails/change?page=" +
          this.$route.query.page;
      } else {
        var url = paginationUrl
          ? paginationUrl
          : process.env.VUE_APP_API_URL + "/emails/change";
      }

      if (this.statusFilter) {
        url = url + "&status=" + this.statusFilter;
      } else {
        url.replace("&status=", "");
      }

      this.$axios(url).then(({ data }) => {
        this.emails = data;
      });
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchChangeEmails();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
